<template>
  <div class="result">
    <template>
      <p class="iconfont icon" style="color: #63ded2">&#xe610;</p>
      <p style="color: #63ded2">订单提交成功</p>
      <ul class="order-info">
        <li class="order">
          <div>订单编号：</div>
          <div>{{ orderNo }}</div>
        </li>
        <li class="alert">
          我们会尽快给您发货，在您收到新的设备卡后，可在【我的】-【更换卡片】自行进行更换
        </li>
      </ul>
    </template>
  </div>
</template>

<script>

export default {
  name: "orderResult",
  data() {
    return {
      type: "result",
      orderNo: "",
    };
  },
  created() {
    this.orderNo = this.$route.params.id;
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.result {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  text-align: center;
  .icon {
    font-size: 51px;
    padding-top: 36px;
    margin-bottom: 16px;
  }
  .order-info {
    padding: 20px 10% 0;
    .order {
      padding: 4px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #323233;
    }
    .alert {
      text-align: left;
      padding-top: 20px;
      font-size: 14px;
    }
  }
}
</style>
